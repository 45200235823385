.playlist-thumbnail {
    width: 200px;
    height: 200px;
    border: 2px solid white;
    border-style: outset;
}

.playlist-obj {
    margin: 20px;
    transition: opacity .1s;
}

.playlist-obj p {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.playlist-obj:hover {
    opacity: 0.7;
    cursor: pointer;
}

.playlist-obj:active {
    position: relative;
    top: 2px;
}
