.top-songs-chart {
  display: block;
  text-align: left;
}

header.top-songs-chart-header {
  display: flex;
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 600;
}

.top-songs-chart-header div.song-ranking-placeholder {
  width: 44px;
}

.top-songs-chart-header div {
  margin-right: 10px;
  text-align: left;
  width: 40%;
}

.top-songs-chart-header .song-artist {
  width: 38%;
}

.top-songs-chart-header div.song-popularity {
  width: 22%;
  text-align: left;
}

@media only screen and (max-width: 500px) {
  .top-songs-chart-header .song-artist {
    width: 40%;
  }
  
  .top-songs-chart-header div.song-popularity {
    display: none;
  }
}