.song-row {
  display: flex;
  padding: 10px 0 10px 0;
  border-top: 1px solid rgba(194, 194, 194, 0.171);
  align-items: center;
}

.song-row div {
  white-space: nowrap;
  margin-right: 10px;
  text-align: left;
  width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.song-row div.song-ranking {
  width: 50px;
  text-align: right;
}

.song-row div.song-artist {
  width: 40%;
}

.song-row div.song-popularity {
  width: 20%
}

@media only screen and (max-width: 500px) {
  .song-row div.song-popularity {
    display: none;
  }
}
