:root {
    --spotify-green: #1ED760;
}

#root, html {
    height: 100%;
}

body {
    background-image: black!important;
    font-family: 'Roberto', sans-serif;
    height: 100%;
}

.main {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100%;
    overflow: hidden;
}

.content {
    flex: 1;
    padding: 20px 20px 0px 20px;
    height: 100%;
    overflow: scroll;
    background-image: linear-gradient(
        to top,
        rgb(22, 22, 22),
        rgb(22, 22, 22),
        rgb(22, 22, 22),
        rgb(22, 22, 22),
        rgb(48, 47, 47),
        rgb(73, 73, 73)
    );
}

.spotify-button {
    width: 100px;
    color: white;
    letter-spacing: 1.76px;
    text-transform: uppercase;
    background-color: #1db954;
    font-size: 10px;
    font-weight: 700;
    border-radius: 20px;
    border: none;
    padding: 10px;
    transition: transform .1s;
    -webkit-transition: -webkit-transform .1s;
  }
  
  .spotify-button:hover {
    background-color: var(--spotify-green);
    cursor: pointer;
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
  }
  
  .spotify-button:active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  .spotify-button:focus {
    outline: none;
  }

@media only screen and (max-width: 850px) {
    body {
        background-image: linear-gradient(
            to bottom,
            rgb(0, 0, 0),
            rgb(22, 22, 22),
            rgb(22, 22, 22),
            rgb(22, 22, 22),
            rgb(48, 47, 47),
            rgb(73, 73, 73)
        );
    }

    .main {
        flex-direction: column;
    }

    .content {
        background-image: none;
    }
}
